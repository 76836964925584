const BeneficiaryService = {
  async getAllBeneficiaries() {
    const response = new Promise<{
      content: Array<BeneficiaryTableItemType>;
      pageNumber: number;
      pageSize: number;
      totalElements: number;
      totalPages: number;
    }>((resolve) =>
      setTimeout(() => {
        resolve({
          content: [
            {
              id: 9876545,
              nickName: 'John Doe Nick',
              name: 'John Doe',
              bankAccountNumber: 1234567890,
              buyCurrency: 'USD',
              country: 'US',
              status: 'ACTIVE',
            },
            {
              id: 9876546,
              nickName: 'John Doe Nick',
              name: 'John Doe',
              bankAccountNumber: 1234567890,
              buyCurrency: 'USD',
              country: 'US',
              status: 'ACTIVE',
            },
            {
              id: 9876547,
              nickName: 'John Doe Nick',
              name: 'John Doe',
              bankAccountNumber: 1234567890,
              buyCurrency: 'USD',
              country: 'US',
              status: 'ACTIVE',
            },
          ],
          pageNumber: 1,
          pageSize: 10,
          totalElements: 27,
          totalPages: 3,
        });
      }, 2000),
    );
    return response;
  },
};

export default BeneficiaryService;
