const settingsInitialConfig: SettingsConfig = {
  administration: [
    {
      name: 'sso',
      header: 'sso',
      subtitle: 'configure_sso',
    },
    {
      name: 'customerManagement',
      header: 'customer_management',
      subtitle: 'setup_child_customers',
    },
  ],
  branding: [
    {
      name: 'branding',
      header: 'branding',
      subtitle: 'adjust_branding',
    },
    {
      name: 'emailSettings',
      header: 'email_settings',
      subtitle: 'configure_email_server',
    },
    {
      name: 'disclaimers',
      header: 'disclaimers',
      subtitle: 'edit_disclaimers',
    },
  ],
  managed_pricing: [
    {
      name: 'margin',
      header: 'margin',
      subtitle: 'configure_margins',
    },
    {
      name: 'fees',
      header: 'fees',
      subtitle: 'configure_fees',
    },
    {
      name: 'seperateRateLockIn',
      header: 'seperate_rate_lock_in',
      subtitle: 'configure_seperate_rate_lock_in',
    },
  ],
  compliance: [
    {
      name: 'sanctionScreening',
      header: 'sanction_screening',
      subtitle: 'enable_sanction_screening_module',
    },
    {
      name: 'doddFrank',
      header: 'dodd_frank',
      subtitle: 'enable_dodd_frank_disclosures',
    },
  ],
};

export default settingsInitialConfig;
