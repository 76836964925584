import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import RoleService from '../../../modules/Manage/Role/roleService';
import { RootState } from '../../rootReducer';

export interface MainState {
  selectedRole: Role | null;
  roleUsers: User[] | null;
  primaryRoles: Role[];
  customRoles: Role[];
}

export const initialState: MainState = {
  selectedRole: null,
  roleUsers: null,
  primaryRoles: [],
  customRoles: [],
};

export const fetchUsersForRole = createAsyncThunk(
  'role/fetchUsersForRole',
  async ({ roleId, customerId }: { roleId: number | string; customerId: number }) => {
    try {
      const users = await RoleService.getRoleUsersById(roleId, customerId);
      return users; // Return the fetched users
    } catch (error) {
      // return rejectWithValue(error?.response?.data);  // Handle errors
    }
  },
);

export const fetchRoles = createAsyncThunk(
  'role/fetchAllRoles',
  async (customerId: number | string) => {
    try {
      const roles = await RoleService.getCustomerRoles(customerId);
      return roles; // Return the fetched users
    } catch (error) {
      // return rejectWithValue(error?.response?.data);  // Handle errors
    }
  },
);

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setSelectedRole: (state: MainState, action: PayloadAction<Role>) => {
      state.selectedRole = action.payload;
      state.roleUsers = null; // Reset roleUsers when a new role is selected
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersForRole.pending, (state) => {
        state.roleUsers = null; // Optional: handle loading state
      })
      .addCase(
        fetchUsersForRole.fulfilled,
        (state, action: PayloadAction<User[] | null | undefined>) => {
          if (action.payload) {
            state.roleUsers = action.payload; // Only set roleUsers if payload exists
          } else {
            state.roleUsers = []; // Set roleUsers to empty array if payload is null or undefined
          }
        },
      )
      .addCase(fetchUsersForRole.rejected, (state) => {
        state.roleUsers = []; // Handle error, set roleUsers to an empty array
      })
      // .addCase(fetchRoles.pending, (state) => {
      //   state.primaryRoles = [];
      //   state.customRoles = [];
      // })
      .addCase(
        fetchRoles.fulfilled,
        (state, action: PayloadAction<RoleResponse | null | undefined>) => {
          if (action.payload) {
            state.primaryRoles = action.payload.primaryRoles.sort((a, b) =>
              a.roleName.localeCompare(b.roleName),
            );
            state.customRoles = action.payload.customRoles.sort((a, b) =>
              a.roleName.localeCompare(b.roleName),
            );
          } else {
            state.primaryRoles = [];
            state.customRoles = [];
          }
        },
      )
      .addCase(fetchRoles.rejected, (state) => {
        state.primaryRoles = [];
        state.customRoles = [];
      });
  },
});

export const { setSelectedRole } = roleSlice.actions;
export const selectSelectedRole = (state: RootState) => state.role.selectedRole;
export const selectRoleUsers = (state: RootState) => state.role.roleUsers;
export const selectPrimaryRoles = (state: RootState) => state.role.primaryRoles;
export const selectCustomRoles = (state: RootState) => state.role.customRoles;
export default roleSlice.reducer;
