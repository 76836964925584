import { DataTable } from 'primereact/datatable';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { Column } from 'primereact/column';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import { Status } from '../../../components';

interface TableProps {
  title: string;
  value: Setting[];
  settingsData?: { [key: string]: { value: boolean; lastUpdated: string | null } };
  handleSettingChange: (e: InputSwitchChangeEvent, name: string) => void;
}

const Table = ({ title, value, settingsData, handleSettingChange }: TableProps) => {
  const header = (
    <div className='text-neutral-1 text-sm-semibold'>
      <Translate value={title} />
    </div>
  );

  return (
    <DataTable size='large' header={header} value={value}>
      <Column
        field='feature'
        className='w-3/6'
        header={translateWithValues('feature')}
        body={(setting: Setting) => (
          <div>
            <div className='text-neutral-2 text-md-medium'>
              <Translate value={setting.header} />
            </div>
            <div className='text-neutral-3 text-md-regular'>
              <Translate value={setting.subtitle} />
            </div>
          </div>
        )}
      />
      <Column
        field='option'
        className='w-1/6'
        header={translateWithValues('enable_or_disable')}
        body={(setting: Setting) => (
          <InputSwitch
            checked={settingsData?.[setting.name]?.value ?? false}
            onChange={(e) => handleSettingChange(e, setting.name)}
          />
        )}
      />
      <Column
        className='w-1/6'
        field='status'
        header={translateWithValues('status')}
        body={(setting: Setting) => (
          <Status status={settingsData?.[setting.name]?.value ? 'ACTIVE' : 'INACTIVE'} />
        )}
      />
      <Column field='lastUpdated' header={translateWithValues('last_updated')} />
    </DataTable>
  );
};

export default Table;
