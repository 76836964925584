import { Translate } from '../../../i18n/translate';
import Table from './Table';
import settingsInitialConfig from './settingsInitialConfig';
import { useQuery } from '@tanstack/react-query';
import ClientService from '../clientService';
import { useState } from 'react';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { AddOrEditOrView, ClientForms } from '../../../types';
import { Button } from 'primereact/button';

interface SettingsProps {
  activeStep?: string;
  mode: AddOrEditOrView;
  jumpToEdit?: (step: ClientForms, client?: Client) => void;
  visited?: Set<string>;
  visitPermitted?: Set<string>;
  onSaveAndProceed?: () => void;
}

const Settings = ({
  activeStep,
  mode,
  jumpToEdit,
  visited,
  visitPermitted,
  onSaveAndProceed,
}: SettingsProps) => {
  const [settingsData, setSettingsData] = useState<{
    [key: string]: { value: boolean; lastUpdated: string | null };
  }>();
  useQuery({
    queryKey: ['client_settings_data'],
    queryFn: async () => {
      const response: { [key: string]: { value: boolean; lastUpdated: string | null } } =
        await ClientService.getClientSettings();
      setSettingsData(response);
    },
  });

  const handleSettingChange = (e: InputSwitchChangeEvent, name: string) => {
    setSettingsData((prev) => {
      const prevCopy = { ...prev };
      prevCopy[name].value = e.value;
      return prevCopy;
    });
  };

  const saveSettings = () => {
    console.log(settingsData);
    if (onSaveAndProceed) {
      onSaveAndProceed();
    }
  };

  return (
    <div className='rounded-c8 shadow-c bg-white p-8'>
      <div className='pb-4 flex justify-between'>
        <div>
          <div className='text-sm-bold'>
            <Translate value='settings.title' />
          </div>
          <div className='text-neutral-3'>
            <Translate value='subheading_placeholder' />
          </div>
        </div>
        {!(activeStep !== ClientForms.Settings && mode != AddOrEditOrView.View) && (
          <div>
            <Button severity='info' onClick={saveSettings}>
              <Translate value='save' />
            </Button>
          </div>
        )}
      </div>
      {activeStep !== ClientForms.Settings && mode != AddOrEditOrView.View ? (
        <div className='mt-4'>
          <Button
            severity='contrast'
            className='!px-14'
            disabled={mode === AddOrEditOrView.Add && !visitPermitted?.has(ClientForms.Settings)}
            onClick={() => {
              if (jumpToEdit) {
                jumpToEdit(ClientForms.Settings);
              }
            }}
          >
            <Translate value={visited?.has(ClientForms.Settings) ? 'edit' : 'start'} />
          </Button>
        </div>
      ) : (
        <div className='flex flex-col gap-10 pt-8 border-t border-t-[1px] pb-3 border-neutral-border-2'>
          {Object.keys(settingsInitialConfig).map((key) => (
            <Table
              title={key}
              key={key}
              value={settingsInitialConfig[key as keyof SettingsConfig]}
              settingsData={settingsData}
              handleSettingChange={handleSettingChange}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Settings;
