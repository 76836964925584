/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { enumToCamelCase } from '../../utils/helper';

const statuses: { [key: string]: StatusColor } = {
  PENDING: {
    name: 'Pending',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  completed: {
    name: 'Completed',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  ACTIVE: {
    name: 'Active',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  INACTIVE: {
    name: 'Inactive',
    backgroundColor: 'bg-neutral-surface-dark',
    textColor: '',
  },
  inProgress: {
    name: 'In Progress',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  failed: {
    name: 'Failed',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  PENDING_CREATION: {
    name: 'Submitted for approval',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  PENDING_APPROVAL: {
    name: 'Pending Approval',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  PENDING_RATE: {
    name: 'Pending Rate',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  EXECUTED: {
    name: 'Executed',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  SubmittingQuote: {
    name: 'Submitting quote request',
    backgroundColor: 'bg-primary-surface-light',
    textColor: 'text-primary-dark-content',
  },
  RecievingData: {
    name: 'Receiving Rate',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  DataRecieved: {
    name: 'Rate Received',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  PaymentStarted: {
    name: 'Payment Started',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  QuoteExpired: {
    name: 'Quote Failed',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  NEW: {
    name: 'New',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  PAYMENT_APPROVAL: {
    name: 'Payment Approval',
    backgroundColor: 'bg-primary-surface-lighter',
    textColor: 'text-selected',
  },
  BENEFICIARY_APPROVAL: {
    name: 'Benificiary Approval',
    backgroundColor: 'bg-primary-surface-lighter',
    textColor: 'text-selected',
  },
  USER_APPROVAL: {
    name: 'User Approval',
    backgroundColor: 'bg-primary-surface-lighter',
    textColor: 'text-selected',
  },
  REJECTED_CREATION: {
    name: 'Rejected',
    backgroundColor: 'bg-error-3',
    textColor: 'text-error-1',
  },
  REJECTED: {
    name: 'Rejected',
    backgroundColor: 'bg-error-3',
    textColor: 'text-error-1',
  },
  CREATED: {
    name: 'Created',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  DELETED: {
    name: 'Deleted',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  ABANDONED: {
    name: 'Abandoned',
    backgroundColor: 'bg-error-3',
    textColor: 'text-error-1',
  },
  READY_FOR_RELEASE: {
    name: 'Ready for Release',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  CANCELLED: {
    name: 'Cancelled',
    backgroundColor: 'bg-error-3',
    textColor: 'text-error-1',
  },
  RELEASED: {
    name: 'Released',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  SUBMITTED: {
    name: 'Submitted',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  APPROVAL_REJECTED: {
    name: 'Approval Rejected',
    backgroundColor: 'bg-error-3',
    textColor: 'text-error-1',
  },
  PENDING_COMPLIANCE: {
    name: 'Pending Compliance',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
  PENDING_PARENT_APPROVAL: {
    name: 'Pending Parent Approval',
    backgroundColor: 'bg-warning-surface-light',
    textColor: 'text-warning-content-dark',
  },
};

const Status = ({ status }: { status: string }) => {
  return statuses[status] ? (
    <div
      className={`rounded-[16px] text-xs-medium ${statuses[status].backgroundColor} ${statuses[status].textColor} py-[2px] px-[8px] w-max`}
    >
      {statuses[status].name}
    </div>
  ) : (
    <div className='bg-neutral-surface-dark'>{enumToCamelCase(status)}</div>
  );
};

export default Status;
