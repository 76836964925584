import { useEffect, useState } from 'react';
import { useLocation, useMatches, useNavigate, useSearchParams } from 'react-router-dom';

const referenceStack: { [key: string]: string } = {};

export default function Breadcrumb() {
  const matches = useMatches();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [parentName, setParentName] = useState<string | null>('');

  useEffect(() => {
    if (searchParams.get('sb') === 'c') {
      if (
        Object.keys(referenceStack).length == 0 ||
        Object.keys(referenceStack)?.find((key) => key !== searchParams.get('name'))
      ) {
        referenceStack[searchParams.get('name') as string] = location.pathname + location.search;
      }
      setParentName(searchParams.get('name'));
    } else {
      setParentName('');
    }
  }, [searchParams]);

  const items = matches
    .filter((match) => (match.handle as { breadcrumb?: string })?.breadcrumb)
    .map((match, index, array) => {
      const breadcrumb = (match.handle as { breadcrumb?: string })?.breadcrumb || '';
      const isLast = index === array.length - 1;

      return (
        <div key={match.pathname} className='flex items-center mb-1.5'>
          <i className='pi pi-chevron-right text-neutral-3 mr-3 text-sm mt-px'></i>
          <button
            onClick={() => {
              navigate(match.pathname);
            }}
            className={`text-lg ${isLast ? 'text-primary font-semibold' : 'text-neutral-3'}`}
            disabled={isLast}
          >
            {breadcrumb}
          </button>
        </div>
      );
    });

  if (parentName) {
    items.unshift(
      <div key='parent' className='flex items-center mb-1.5'>
        <i className='pi pi-chevron-right text-neutral-3 mr-3 text-sm mt-px'></i>
        <button
          onClick={() => {
            if (searchParams.get('sb') === 'c') {
              const index = Object.keys(referenceStack).findIndex((key) => key === parentName);
              if (index - 1 > -1) {
                navigate(Object.values(referenceStack)[index - 1]);
              } else {
                navigate('/client');
              }
            }
          }}
          className='text-lg text-neutral-3'
        >
          {parentName}
        </button>
      </div>,
    );
  }

  return (
    <>
      <div className='block sm:hidden'>
        {items.length > 1 ? (
          <button className='text-primary' onClick={() => navigate(-1)}>
            <i className='pi pi-chevron-left w-6 h-6 text-primary mr-5 ml-5'></i>
          </button>
        ) : (
          <div
            id='clientLogo'
            style={{ width: '11.905rem', height: '20px' }}
            className='client-logo-dark bg-contain ml-4 bg-no-repeat block sm:hidden'
          />
        )}
      </div>
      <div className='hidden sm:flex items-center space-x-3'>
        {/* Back Button */}
        {items.length > 1 && (
          <button className='text-primary' onClick={() => navigate(-1)}>
            <i className='pi pi-chevron-left w-6 h-6 text-primary mr-5 ml-5'></i>
          </button>
        )}
        {/* Home Icon */}
        <button onClick={() => navigate('/')} className={`mr-5 ${items.length < 2 ? 'ml-5' : ''}`}>
          <i className='pi pi-home text-neutral-3 text-[20px]'></i>
        </button>
        {/* Breadcrumb Items */}
        {items}
      </div>
    </>
  );
}
