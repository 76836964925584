import { Edit, View } from '@carbon/icons-react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InheritedMenu } from '../../../components';
import InfoTemplate from '../../../components/InfoTemplate';
import { MoreAction } from '../../../icons';
import { PageHeadContext } from '../../../providers/PageHead';
import { useAppDispatch } from '../../../store';
import AddSchedule from './AddSchedule';
import ScheduleService from './scheduleService';

export default function ScheduleReports() {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [addScheduleToggle, setAddScheduleToggle] = useState(false);
  const [schedules, setSchedules] = useState<any[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<any | null>(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const menu = useRef<Menu>(null);
  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setPageHeadData({
      title: 'scheduledReportsConf',
      description: 'scheduledReportsConfDesc',
      className: 'pb-4 pr-4',
      actionUI: (
        <InfoTemplate
          titleKey='scheduleReports.addnew.title'
          descriptionKey='scheduleReports.addnew.description'
          buttonKey='scheduleReports.addnew.button'
          btnAction={() => {
            setAddScheduleToggle(true);
          }}
          // roles={clientRole.AddClient}
        />
      ),
    });

    const fetchSchedules = async () => {
      const data: any[] = (await ScheduleService.getSchedules()) as any[];
      setSchedules(data);
    };

    fetchSchedules();
  }, [setPageHeadData, navigate, searchParams, dispatch]);

  const actionBodyTemplate = (schedule: any) => {
    const actionFilter = [
      {
        label: 'View',
        icon: <View />,
        onClick: () => {
          setSelectedSchedule(schedule);
          setIsViewMode(true);
          setAddScheduleToggle(true);
        },
      },
      {
        label: 'Edit',
        icon: <Edit />,
        onClick: () => {
          setSelectedSchedule(schedule);
          setIsViewMode(false);
          setAddScheduleToggle(true);
        },
      },
    ];

    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActions(actionFilter);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  return (
    <>
      {addScheduleToggle && (
        <AddSchedule
          visible={addScheduleToggle}
          setAddScheduleToggle={setAddScheduleToggle}
          schedule={selectedSchedule}
          isViewMode={isViewMode}
        />
      )}
      <div className='p-4'>
        <DataTable value={schedules} paginator rows={10} className='p-datatable-gridlines'>
          <Column field='id' header='ID' sortable />
          <Column field='frequency' header='Frequency' sortable />
          <Column field='time' header='Time' sortable />
          <Column field='reportFormat' header='Report Format' sortable />
          <Column field='deliveryOptions' header='Delivery Options' sortable />
          <Column field='recipient' header='Recipient' sortable />
          <Column field='customerId' header='Customer ID' sortable />
          <Column body={actionBodyTemplate} header='Actions' />
        </DataTable>
      </div>
    </>
  );
}
