const dummySchedules = [
  {
    id: 1,
    frequency: 'Daily',
    time: '08:00 AM',
    reportFormat: ['PDF', 'Excel'],
    deliveryOptions: ['Email'],
    recipient: 'john.doe@example.com',
    customerId: '12345',
  },
  {
    id: 2,
    frequency: 'Weekly',
    time: '09:00 AM',
    reportFormat: ['CSV'],
    deliveryOptions: ['FTP'],
    recipient: 'jane.doe@example.com',
    customerId: '67890',
  },
];

const ScheduleService = {
  getSchedules: async () => {
    // Simulate an API call with a delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(dummySchedules);
      }, 1000);
    });
  },
};

export default ScheduleService;
