import {
  CheckmarkOutline,
  CloseOutline,
  Edit,
  Filter,
  TrashCan,
  WatsonHealthStudyPrevious,
} from '@carbon/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FilterMatchMode } from 'primereact/api';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  InheritedMenu,
  PaginatorTemplate,
  Status as StatusComp,
  TableSearch,
} from '../../../../components';
import FilterModal from '../../../../components/FilterModal';
import useRole from '../../../../hooks/useRoles';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { MoreAction } from '../../../../icons';
import { selectFilters, sendToast, updateFilter } from '../../../../store/slices/main/mainSlice';
import { Status } from '../../../../types';
import { convertDateFormat } from '../../../../utils/helper';
import { STATUS_OPTIONS, STRUCTURE_TYPES } from '../approvalsConstants';
import approvalsRoles from '../approvalsRoles';
import ApprovalsSerive from '../approvalsService';
import ActionConfirmPopup from './ActionConfirmPopup';
import ViewApproversDialog from './ViewApproversDialog';

type Props = {
  feature: FeatureType;
  data: Array<ApprovalStructure>;
  isFetching: boolean;
};

const ApprovalsTable = ({ feature, data, isFetching }: Props) => {
  const [selectedStructuresIds, setSelectedStructureIds] = useState<Array<number>>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [searchFilter, setGlobalFilter] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [currentActionStructure, setCurrentActionStructure] = useState<ApprovalStructure | null>(
    null,
  );
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
  const [showDeactivateConfirmPopup, setShowDeactivateConfirmPopup] = useState(false);
  const [showViewApproversDialog, setShowViewApproversDialog] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const filters = useSelector(selectFilters)[`approvals_${feature}`];
  const [filterValues, setFilterValues] = useState([
    {
      header: translateWithValues('by_status'),
      fields: [
        {
          name: 'statuses',
          placeholder: translateWithValues('by_status_placeholder'),
          value: (filters?.statuses || []) as Array<string>,
          options: STATUS_OPTIONS,
        },
      ],
    },
    {
      header: translateWithValues('by_structure_type'),
      fields: [
        {
          name: 'structureTypes',
          placeholder: translateWithValues('by_structure_type_placeholder'),
          value: (filters?.structureTypes || []) as Array<string>,
          options:
            feature === 'PAYMENT'
              ? STRUCTURE_TYPES
              : STRUCTURE_TYPES.filter((item) => item.value !== 'TIERED'),
        },
      ],
    },
  ]);
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const menu = useRef<Menu>(null);

  const dispatch = useDispatch();
  const { hasRole } = useRole();

  const selectedStructures = selectedStructuresIds.map(
    (id) => data.find((item) => item.id === id)!,
  );

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const _filters = { ...searchFilter };
    _filters['global'].value = value.trim();
    setGlobalFilter(_filters);
    setGlobalFilterValue(value);
  };

  const handleApplyFilters = (formdata: FilterFormData) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    dispatch(updateFilter({ filterKey: `approvals_${feature}`, value: formdata }));
  };

  const filterByStatus = (list: Array<ApprovalStructure>) => {
    return !filters || !filters.statuses || filters.statuses.length === 0
      ? list
      : list.filter((item) => filters.statuses.includes(item.status));
  };

  const filterByStructureType = (list: Array<ApprovalStructure>) => {
    return !filters || !filters.structureTypes || filters.structureTypes.length === 0
      ? list
      : list.filter((item) => filters.structureTypes.includes(item.appStructure));
  };

  const filteredData = filterByStatus(filterByStructureType(data));

  const tableHeader = (
    <div className='flex flex-col lg:flex-row gap-3 justify-between'>
      <div className='flex flex-col gap-1'>
        <div className='text-neutral-1 text-lg-semibold'>
          <Translate value='payment_table.heading' />
          <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
            {filteredData.length}
          </span>
        </div>
        <Translate value='payment_table.subtitle' className='text-neutral-3 text-sm-regular' />
      </div>
      <div className='flex justify-around gap-2 items-center'>
        <TableSearch
          globalFilterValue={globalFilterValue}
          onGlobalFilterChange={onGlobalFilterChange}
        />
        <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
          <Button
            severity='secondary'
            className='p-button-text h-12'
            onClick={() => setShowFiltersModal(true)}
          >
            <Filter size={20} /> <Translate value='filterLabel' className='ml-1' />
          </Button>
          {!!activeFilterCount && (
            <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
          )}
        </div>
        {hasRole(approvalsRoles.AddApprovalFlow) && (
          <div>
            <Button
              severity='info'
              className='p-button-text min-h-12'
              onClick={() => {
                navigate(`add-structure?feature=${feature}`);
              }}
            >
              <Translate value='create_new_structure' className='ml-1' />
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  const actionBodyTemplate = (structure: ApprovalStructure) => {
    let actions: MenuItem[] = [
      {
        roles: approvalsRoles.UpdateApprovalFlow,
        label: 'approvals.menu.view.structure',
        icon: <WatsonHealthStudyPrevious />,
        onClick: () => navigate(`view-strucutre?modelId=${structure?.id}`),
      },
      {
        roles: approvalsRoles.UpdateApprovalFlow,
        label: 'approvals.menu.edit_structure',
        disabled:
          structure.appStructure === 'SIMPLE' ||
          [Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(structure.approvalStatus),
        icon: <Edit />,
        onClick: () => {
          navigate(`edit-structure?modelId=${structure?.id}&feature=${feature}`);
        },
      },
      {
        roles: approvalsRoles.UpdateApprovalFlow,
        label: 'approvals.menu.view_approvers',
        disabled: [Status.PENDING_CREATION].includes(structure.approvalStatus),
        icon: <WatsonHealthStudyPrevious />,
        onClick: () => setShowViewApproversDialog(true),
      },
      {
        roles: approvalsRoles.UpdateApprovalFlow,
        label: structure?.status === 'ACTIVE' ? 'deactivate' : 'activate',
        disabled:
          (structure?.status === 'ACTIVE' && structure.appStructure === 'SIMPLE') ||
          [Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(structure.approvalStatus),
        icon: <CloseOutline />,
        onClick: () => {
          if (structure?.status === 'ACTIVE') {
            setShowDeactivateConfirmPopup(true);
          } else if (
            structure?.status === 'INACTIVE' &&
            data.some(
              (item) => item.appStructure === structure.appStructure && item.status === 'ACTIVE',
            )
          ) {
            dispatch(
              sendToast({
                summary: 'error',
                severity: 'error',
                detail: 'approvals.activate_integrity_error',
              }),
            );
          } else {
            activateSingleStructureMutation.mutate();
          }
        },
      },
      {
        roles: approvalsRoles.DeleteApprovalFlow,
        disabled:
          structure.appStructure === 'SIMPLE' ||
          [Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(structure.approvalStatus),
        label: 'delete',
        icon: <TrashCan />,
        onClick: () => setShowDeleteConfirmPopup(true),
        className: 'delete-menu-item',
      },
    ];
    if ([Status.PENDING_CREATION, Status.PENDING_APPROVAL].includes(structure.approvalStatus)) {
      if (structure.canBeApprovedByCurrentUser) {
        actions.unshift({
          label: 'approve_structure.approve',
          icon: <CheckmarkOutline />,
          onClick: () => {
            navigate(`update-status?modelId=${structure?.id}`);
          },
        });
      }
    }
    if (structure.status === Status.REJECTED_CREATION) {
      actions = [
        {
          roles: approvalsRoles.UpdateApprovalFlow,
          label: 'approvals.menu.view.structure',
          icon: <WatsonHealthStudyPrevious />,
          onClick: () => navigate(`view-strucutre?modelId=${structure?.id}`),
        },
      ];
    }
    return (
      <div>
        <button
          onClick={(event) => {
            setCurrentActionStructure(structure);
            setCurrentActions(actions);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </button>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const deactivateSelectedMutation = useMutation({
    mutationFn: async () => {
      const response = await ApprovalsSerive.deactivateApprovalStructure(
        selectedStructures.filter((s) => s.status === 'ACTIVE').map((s) => s.id),
      );
      return response;
    },
    onSuccess: () => {
      setSelectedStructureIds([]);
      queryClient.invalidateQueries({ queryKey: ['approvals_list', feature] });
      dispatch(
        sendToast({
          summary: 'success',
          severity: 'success',
          detail: 'approvals.deactivate_selected_success',
        }),
      );
    },
    onError: () => {
      dispatch(
        sendToast({
          summary: 'error',
          severity: 'error',
          detail: 'approvals.deactivate_selected_error',
        }),
      );
    },
  });

  const deletedSelectedMutation = useMutation({
    mutationFn: async () => {
      const response = await ApprovalsSerive.delete(
        selectedStructures.filter((s) => s.status === 'ACTIVE').map((s) => s.id),
      );
      return response;
    },
    onSuccess: () => {
      setSelectedStructureIds([]);
      queryClient.invalidateQueries({ queryKey: ['approvals_list', feature] });
      dispatch(
        sendToast({
          summary: 'success',
          severity: 'success',
          detail: 'approvals.deactivate_selected_success',
        }),
      );
    },
    onError: () => {
      dispatch(
        sendToast({
          summary: 'error',
          severity: 'error',
          detail: 'approvals.deactivate_selected_error',
        }),
      );
    },
  });

  const activateSelectedMutation = useMutation({
    mutationFn: async () => {
      const response = await ApprovalsSerive.activateApprovalStructure(
        selectedStructures.filter((s) => s.status === 'INACTIVE').map((s) => s.id),
      );
      return response;
    },
    onSuccess: () => {
      setSelectedStructureIds([]);
      queryClient.invalidateQueries({ queryKey: ['approvals_list', feature] });
      dispatch(
        sendToast({
          summary: 'success',
          severity: 'success',
          detail: 'approvals.activate_selected_success',
        }),
      );
    },
    onError: (error) => {
      if (error.message === 'MODEL_INTEGRITY') {
        queryClient.invalidateQueries({ queryKey: ['approvals_list', feature] });
      }
      dispatch(
        sendToast({
          summary: 'error',
          severity: 'error',
          detail:
            error.message === 'MODEL_INTEGRITY'
              ? 'approvals.activate_integrity_error'
              : 'approvals.activate_selected_error',
        }),
      );
    },
  });

  const activateSingleStructureMutation = useMutation({
    mutationFn: async () => {
      if (currentActionStructure) {
        const response = await ApprovalsSerive.activateApprovalStructure([
          currentActionStructure.id,
        ]);
        return response;
      }
      throw Error('No structure selected');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['approvals_list', feature] });
      dispatch(
        sendToast({
          summary: 'success',
          severity: 'success',
          detail: 'approvals.activate_success',
        }),
      );
    },
    onError: (error) => {
      dispatch(
        sendToast({
          summary: 'error',
          severity: 'error',
          detail:
            error.message === 'MODEL_INTEGRITY'
              ? 'approvals.activate_integrity_error'
              : 'approvals.activate_error',
        }),
      );
    },
  });

  const getApproversColumnText = (approvalStructure: ApprovalStructure) => {
    if (approvalStructure.appModelConfigs.length === 0) {
      return 'N/A';
    }

    const firstApproverName =
      approvalStructure.appModelConfigs[0].appApprovers.length &&
      'userName' in approvalStructure.appModelConfigs[0].appApprovers[0]
        ? approvalStructure.appModelConfigs[0].appApprovers[0].userName
        : '';

    const totalApproversCount = approvalStructure.appModelConfigs.reduce(
      (acc, curr) => acc + curr.appApprovers.length,
      0,
    );

    const text = firstApproverName
      ? `${firstApproverName}${totalApproversCount - 1 > 0 ? ` + ${totalApproversCount - 1} more` : ''}`
      : `${totalApproversCount} approvers`;

    return text;
  };

  return (
    <div>
      <DataTable
        size='large'
        header={tableHeader}
        value={filteredData}
        paginator
        first={0}
        rows={10}
        totalRecords={data.length}
        paginatorTemplate={PaginatorTemplate}
        loading={isFetching}
        selection={selectedStructures}
        selectionMode={'checkbox'}
        onSelectionChange={(e) =>
          setSelectedStructureIds(e.value.map((s: ApprovalStructure) => s.id))
        }
        dataKey='id'
        globalFilterFields={['modelName', 'structureType', 'status', 'dateCreated']}
        filters={searchFilter}
      >
        <Column selectionMode='multiple' headerStyle={{ width: '1rem' }} />
        <Column sortable field='modelName' header={<Translate value='structure_name' />} />
        <Column
          header={<Translate value='date_created' />}
          body={(approvalStructure: ApprovalStructure) =>
            approvalStructure.createdAt ? convertDateFormat(approvalStructure.createdAt) : 'N/A'
          }
        />
        <Column
          header={<Translate value='levels' />}
          body={(approvalStructure: ApprovalStructure) => approvalStructure.appModelConfigs.length}
        />
        <Column
          header={<Translate value='structure_type' />}
          body={(approvalStructure: ApprovalStructure) => (
            <span className='capitalize'>{approvalStructure.appStructure.toLowerCase()}</span>
          )}
        />
        <Column
          header={<Translate value='approvers' />}
          body={(approvalStructure: ApprovalStructure) => getApproversColumnText(approvalStructure)}
        />
        <Column
          sortable
          field='status'
          header='Status'
          body={(approvalStructure: ApprovalStructure) => {
            return (
              <StatusComp
                status={
                  approvalStructure.approvalStatus == Status.PENDING_APPROVAL ||
                  approvalStructure.approvalStatus == Status.PENDING_CREATION
                    ? approvalStructure.approvalStatus
                    : approvalStructure.status
                }
              />
            );
          }}
        />
        <Column header='Actions' body={actionBodyTemplate} />
      </DataTable>
      <div className='flex flex-col sm:flex-row items-center justify-center gap-4 pt-8 flex-wrap'>
        <Button
          severity='contrast'
          className='w-full sm:w-56 justify-center gap-2'
          disabled={
            selectedStructures.filter((s) => s.status === 'INACTIVE').length === 0 ||
            selectedStructures.filter(
              (s) =>
                s.approvalStatus == Status.PENDING_APPROVAL ||
                s.approvalStatus == Status.PENDING_CREATION,
            ).length !== 0
          }
          onClick={() => {
            if (
              selectedStructures.some((structure) =>
                data.some(
                  (item) =>
                    item.appStructure === structure.appStructure && item.status === 'ACTIVE',
                ),
              )
            ) {
              dispatch(
                sendToast({
                  summary: 'error',
                  severity: 'error',
                  detail: 'approvals.activate_integrity_error_selected',
                }),
              );
            } else {
              activateSelectedMutation.mutate();
            }
          }}
          loading={activateSelectedMutation.isPending}
        >
          <Translate value='activate_selected' />
        </Button>
        <Button
          severity='contrast'
          className='w-full sm:w-56 justify-center gap-2'
          disabled={
            selectedStructures.filter((s) => s.status === Status.ACTIVE).length === 0 ||
            selectedStructures.filter(
              (s) =>
                s.approvalStatus == Status.PENDING_APPROVAL ||
                s.approvalStatus == Status.PENDING_CREATION,
            ).length !== 0
          }
          onClick={() => deactivateSelectedMutation.mutate()}
          loading={deactivateSelectedMutation.isPending}
        >
          <Translate value='deactivate_selected' />
        </Button>
        <Button
          severity='info'
          className='w-full sm:w-56 justify-center gap-2'
          disabled={
            selectedStructuresIds.length === 0 ||
            selectedStructures.filter(
              (s) =>
                s.approvalStatus == Status.PENDING_APPROVAL ||
                s.approvalStatus == Status.PENDING_CREATION,
            ).length !== 0
          }
          onClick={() => {
            deletedSelectedMutation.mutate();
          }}
          loading={deletedSelectedMutation.isPending}
        >
          <Translate value='delete_selected' />
        </Button>
      </div>
      {showDeleteConfirmPopup && currentActionStructure && (
        <ActionConfirmPopup
          action='delete'
          onHide={() => setShowDeleteConfirmPopup(false)}
          approvalStructure={currentActionStructure}
          feature={feature}
        />
      )}
      {showDeactivateConfirmPopup && currentActionStructure && (
        <ActionConfirmPopup
          action='deactivate'
          approvalStructure={currentActionStructure}
          onHide={() => setShowDeactivateConfirmPopup(false)}
          feature={feature}
        />
      )}
      {showViewApproversDialog && currentActionStructure && (
        <ViewApproversDialog
          onHide={() => setShowViewApproversDialog(false)}
          hideModal={() => setShowViewApproversDialog(false)}
          approvalDetails={currentActionStructure}
        />
      )}
      <FilterModal
        visible={showFiltersModal}
        setFilterModalToggle={setShowFiltersModal}
        filters={filterValues}
        setFilters={setFilterValues}
        title='approvals_filter'
        onApply={handleApplyFilters}
      />
    </div>
  );
};

export default ApprovalsTable;
