import { useSelector } from 'react-redux';
import { CustomTabView } from '../../../components';
import { translateWithValues } from '../../../i18n/translate';
import { selectAoboExist, selectLoading } from '../../../store/slices/main/mainSlice';
import Table from './Table';
import { useSearchParams } from 'react-router-dom';

const Dashboard = () => {
  const aoboExist = useSelector(selectAoboExist);
  const isLoading = useSelector(selectLoading);
  const [searchParams] = useSearchParams();
  const tabs = [
    {
      header: translateWithValues('client.all.status'),
      content: <Table pending={false} />,
      loading: isLoading,
    },
    {
      header: translateWithValues('client.pending'),
      content: <Table pending={true} />,
      loading: isLoading,
    },
  ];

  return (
    <div>
      <CustomTabView
        tabs={aoboExist ? [tabs[0]] : tabs}
        initialIndex={searchParams.get('pending') ? 1 : 0}
      />
    </div>
  );
};

export default Dashboard;
