import { Column } from 'primereact/column';
import {
  DataTable,
  type DataTablePageEvent,
  type DataTableSortEvent,
  type SortOrder,
} from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  InheritedMenu,
  PageHead,
  PaginatorTemplate,
  Status,
  TableSearch,
} from '../../../components';
import { Translate } from '../../../i18n/translate';
import { MoreAction } from '../../../icons';
import { setSelectedRole } from '../../../store/slices/role/roleSlice';
import BeneficiaryService from '../beneficiaryService';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';
import { Filter } from '@carbon/icons-react';
import { Badge } from 'primereact/badge';
import { selectFilters, updateFilter } from '../../../store/slices/main/mainSlice';
import FilterModal from '../../../components/FilterModal';
import { BENEFICIARY_STATUS_OPTIONS } from '../beneficiariesConstants';

export default function ManageBeneficiaries() {
  const dispatch = useDispatch();

  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<SortOrder>();
  const filters = useSelector(selectFilters)['beneficiaries_filter'];
  const [activeFilterCount, setActiveFilterCount] = useState(
    filters ? Object.keys(filters).length : 0,
  );
  const [isModalVisible, setModalVisible] = useState(false);

  const [filterValues, setFilterValues] = useState<any>([
    {
      header: 'By Status',
      fields: [
        {
          name: 'statuses',
          placeholder: 'Select Status',
          value: filters?.statuses || [],
          options: BENEFICIARY_STATUS_OPTIONS,
        },
      ],
    },
  ]);

  const navigate = useNavigate();

  const menu = useRef<Menu>(null);

  const onSort = (event: DataTableSortEvent) => {
    if (event.sortField && event.sortOrder) {
      setSortField(event.sortField);
      setSortOrder(event.sortOrder);
    }
  };

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const handleApplyFilters = (formdata: { [key: string]: string[] | string }) => {
    const filterCount = Object.values(formdata).filter((arr) => arr?.length).length;
    setActiveFilterCount(filterCount);
    setPageParams({
      ...pageParams,
      page: 0,
      first: 0,
    });
    dispatch(updateFilter({ filterKey: 'payments_filter', value: formdata }));
  };

  const beneficiaryListQuery = useQuery({
    queryKey: ['beneficiary_list'],
    queryFn: async () => {
      return await BeneficiaryService.getAllBeneficiaries();
    },
  });

  if (beneficiaryListQuery.isLoading) {
    return <div className='text-center text-neutral-3'>Loading beneficiary list...</div>;
  }

  if (beneficiaryListQuery.isError) {
    return <div className='text-center text-neutral-3'>Error fetching beneficiary list</div>;
  }

  const actionBodyTemplate = (role: Role) => {
    const beneficiaryActions: MenuItem[] = [];

    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActions(beneficiaryActions);
            dispatch(setSelectedRole(role));
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div className='text-neutral-1 text-lg-semibold'>
        <Translate value='total_beneficiary_requests' />
        <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
          {beneficiaryListQuery.data?.totalElements ?? 0}
        </span>
      </div>
      <div className='flex justify-around gap-2 items-center'>
        <TableSearch
          globalFilterValue={globalSearch}
          onGlobalFilterChange={(e) => {
            setGlobalSearch(e.target.value);
          }}
        />
        <div className={`ml-2 relative ${activeFilterCount ? 'mr-1' : ''}`}>
          <Button
            severity='secondary'
            className='p-button-text h-12'
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <Filter size={20} />
            <Translate value='filterLabel' className='ml-1 hidden md:hidden lg:block' />{' '}
          </Button>
          {!!activeFilterCount && (
            <Badge value={activeFilterCount} className='bg-primary absolute -top-2 -right-2' />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className='px-[20px] lg:px-[49px] py-[16px] bg-white border-b border-neutral-border'>
        <PageHead
          title='beneficiaries.title'
          description='beneficiaries.description'
          button={
            <Button
              severity='info'
              className='h-[40px]'
              onClick={() => {
                navigate('/beneficiaries/create');
              }}
            >
              <Translate value='create_beneficiary' />
            </Button>
          }
        />
      </div>
      <div className='dashboard-padding'>
        <DataTable
          paginator={true}
          lazy
          paginatorTemplate={PaginatorTemplate}
          first={pageParams.first}
          rows={pageParams.rows}
          totalRecords={beneficiaryListQuery.data?.totalElements ?? 0}
          loading={beneficiaryListQuery.isFetching}
          onPage={onPage}
          header={header}
          value={beneficiaryListQuery.data?.content}
          size={'large'}
          dataKey='reportId'
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
          className='mt-8'
        >
          <Column field='id' header={<Translate value='beneficiary_id' />}></Column>
          <Column field='nickName' header={<Translate value='beneficiary_nick_name' />}></Column>
          <Column field='name' header={<Translate value='beneficiary_name' />}></Column>
          <Column
            field='bankAccountNumber'
            header={<Translate value='beneficiary_account_number' />}
          ></Column>
          <Column field='buyCurrency' header={<Translate value='buy_currency' />}></Column>
          <Column field='country' header={<Translate value='country' />}></Column>
          <Column
            field='status'
            header={<Translate value='status' />}
            body={(beneficiary: BeneficiaryTableItemType) => <Status status={beneficiary.status} />}
          ></Column>
          <Column header='Actions' body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        setFilters={setFilterValues}
        title='paymentFilter'
        onApply={handleApplyFilters}
      />
    </>
  );
}
