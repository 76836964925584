import {
  AppConnectivity,
  ChartColumnFloating,
  ChartPie,
  DataBase,
  Document,
  Money,
  Notification,
  UserMultiple,
} from '@carbon/icons-react';
import { RouteRoles } from '../RouteRoles';

const menuItems = [
  {
    key: 'dashboard',
    link: '/',
    icon: <ChartColumnFloating size={24} />,
  },
  {
    key: 'payments',
    icon: <Money size={24} />,
    roles: RouteRoles.payment,
    children: [
      {
        key: 'rate_requests.title',
        link: '/payment/rate-requests',
        roles: RouteRoles.payment,
      },
    ],
  },
  {
    key: 'clients',
    link: '/client',
    roles: RouteRoles.client,
    icon: <UserMultiple size={24} />,
  },
  {
    key: 'reports',
    icon: <Document size={24} />,
    children: [
      {
        key: 'viewReports',
        link: '/reports/view',
        roles: RouteRoles.report,
      },
      {
        key: 'scheduledReportsConf',
        link: '/reports/schedule',
        roles: RouteRoles.report,
      },
      {
        key: 'generateReports',
        link: '/reports/generate',
        roles: RouteRoles.report,
      },
    ],
  },
  {
    key: 'configuration',
    icon: <ChartPie size={24} />,
    roles: RouteRoles.configuration,
    children: [
      {
        key: 'Currency',
        roles: RouteRoles.Currency,
        link: '/configuration/currency',
      },
      {
        key: 'Managed Pricing',
        roles: RouteRoles.ManagedPricing,
        link: '/configuration/managed-pricing',
      },
      {
        key: 'disclaimer.title',
        roles: RouteRoles.configuration,
        link: '/configuration/disclaimer',
      },
      {
        roles: RouteRoles.configuration,
        key: 'email_settings.title',
        link: '/configuration/email-settings',
      },
      {
        roles: RouteRoles.branding,
        key: 'client_branding.menu.title',
        link: '/configuration/branding',
      },
    ],
  },
  {
    key: 'manage',
    icon: <AppConnectivity size={24} />,
    roles: RouteRoles.manage,
    children: [
      {
        roles: RouteRoles.User,
        key: 'users',
        link: '/manage/user',
      },
      {
        roles: RouteRoles.Roles,
        key: 'roles',
        link: '/manage/role',
      },
      {
        roles: RouteRoles.approvals,
        key: 'approvals',
        link: '/manage/approvals',
      },
    ],
  },
  {
    key: 'notifications',
    icon: <Notification size={24} />,
    link: '/notification',
  },
  {
    key: 'beneficiaries',
    icon: <DataBase size={24} />,
    children: [
      {
        roles: '',
        key: 'manage_beneficiaries',
        link: '/beneficiaries/manage',
      },
      {
        roles: '',
        key: 'create_beneficiary',
        link: '/beneficiaries/create',
      },
    ],
  },
];

export default menuItems;
