const oktaConfig: OktaConfig = {
  'http://localhost:3000': {
    clientId: '0oagzqsfvjPVJrz461d7',
    baseUrl: 'https://stonex-sandbox.oktapreview.com/oauth2/ausgv0vixm7ZPH79I1d7',
  },
  'https://gpwl.dev.stonex.com': {
    clientId: '0oagzqsfvjPVJrz461d7',
    baseUrl: 'https://stonex-sandbox.oktapreview.com/oauth2/ausgv0vixm7ZPH79I1d7',
  },
  'https://wlclient.gpwl.dev.stonex.com': {
    clientId: '0oah7kuzxfiUGFxVi1d7',
    baseUrl: 'https://stonex-sandbox.oktapreview.com/oauth2/ausgv0vixm7ZPH79I1d7',
  },
  'https://gpwl.qa.stonex.com': {
    clientId: '0oaheg4qcrocf70KB1d7',
    baseUrl: 'https://auth.preview.stonex.com/oauth2/ausheinoh6L5GwxJq1d7',
  },
  'https://wlclient.gpwl.qa.stonex.com': {
    clientId: '0oahehxlglXPgzCyh1d7',
    baseUrl: 'https://auth.preview.stonex.com/oauth2/ausheinoh6L5GwxJq1d7',
  },
  'https://spwl.qa.stonex.com': {
    clientId: '0oajaem9u94KausMT1d7',
    baseUrl: 'https://auth.preview.spwl.stonex.com/oauth2/aushru8h34208j5a31d7',
  },
  'https://wlclient.spwl.qa.stonex.com': {
    clientId: '0oajafuiqyParmVyE1d7',
    baseUrl: 'https://auth.preview.spwl.stonex.com/oauth2/aushru8h34208j5a31d7',
  },
  'https://gpwl.admin.dev.corp.stonex.me': {
    clientId: '0oahil294mV0CWQc11d7',
    baseUrl: 'https://stonex-sandbox.oktapreview.com/oauth2/ausgv0vixm7ZPH79I1d7',
  },
  'https://gpwl.admin.qa.corp.stonex.me': {
    clientId: '0oahehxlglXPgzCyh1d7',
    baseUrl: 'https://auth.preview.stonex.com/oauth2/ausheinoh6L5GwxJq1d7',
  },
  'https://spwl.uat.stonex.com': {
    clientId: '0oahruc3urXVma0bv1d7',
    baseUrl: 'https://auth.preview.spwl.stonex.com/oauth2/aushru8h34208j5a31d7',
  },
  'https://wlclient.spwl.uat.stonex.com': {
    clientId: '0oahru7g2uIf8Ci7A1d7',
    baseUrl: 'https://auth.preview.spwl.stonex.com/oauth2/aushru8h34208j5a31d7',
  },
  'https://spwl.admin.uat.corp.stonex.me': {
    clientId: '0oai7ivbkxn0SfwUZ1d7',
    baseUrl: 'https://auth.preview.spwl.stonex.com/oauth2/aushru8h34208j5a31d7',
  }
};

export default oktaConfig;
