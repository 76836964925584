const settingsMockData: { [key: string]: { value: boolean; lastUpdated: string | null } } = {
  sso: {
    value: true,
    lastUpdated: null,
  },
  customerManagement: {
    value: true,
    lastUpdated: null,
  },
  branding: {
    value: true,
    lastUpdated: null,
  },
  emailSettings: {
    value: false,
    lastUpdated: null,
  },
  disclaimers: {
    value: false,
    lastUpdated: null,
  },
  margin: {
    value: true,
    lastUpdated: null,
  },
  fees: {
    value: true,
    lastUpdated: null,
  },
  seperateRateLockIn: {
    value: true,
    lastUpdated: null,
  },
  sanctionScreening: {
    value: true,
    lastUpdated: null,
  },
  doddFrank: {
    value: false,
    lastUpdated: null,
  },
};

export default settingsMockData;
