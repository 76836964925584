import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { useContext, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextInput } from '../../../components';
import { Translate, translateWithValues } from '../../../i18n/translate';
import { PageHeadContext } from '../../../providers/PageHead';
import { useAppDispatch } from '../../../store';
import { sendToast } from '../../../store/slices/main/mainSlice';
import { DELIVERY_OPTIONS, REPORT_FORMAT_OPTIONS } from '../report';

const GenerateReports = () => {
  const { handleSubmit, control, reset } = useForm();
  const formRef = useRef(null);
  const dispatch = useAppDispatch();
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  useEffect(() => {
    reset({});
  }, [reset]);

  useEffect(() => {
    setPageHeadData({
      title: 'generateReports',
      description: 'generateReportsDesc',
      className: 'pb-4 pr-4',
    });
  }, [setPageHeadData, dispatch]);

  const showToast = (severity: 'success' | 'error', summary: string, detail: string) => {
    dispatch(sendToast({ severity, summary, detail }));
  };

  const onGenerate = () => {
    // Handle generate logic here
    showToast('success', 'Success', 'Report generated successfully');
  };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onGenerate)} ref={formRef}>
        <div className='!p-8 card mt-8'>
          <p className='text-xs-semibold'>
            <Translate value='selectReportType' />
          </p>
          <p className='text-md-regular text-neutral-3'>
            <Translate value='selectReportTypeDesc' />
          </p>
          <Controller
            name='reportType'
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                options={REPORT_FORMAT_OPTIONS}
                placeholder={translateWithValues('selectReportFormat')}
                className='w-full mt-4'
              />
            )}
          />
        </div>
        <div className='!p-8 card mt-8'>
          <div>
            <p className='text-xs-semibold'>
              <Translate value='setReportParamTitle' />
            </p>
            <p className='text-md-regular text-neutral-3'>
              <Translate value='setReportParamDesc' />
            </p>
          </div>
          <div className='lg:mx-5 mb-6 mt-4 grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
            <Controller
              name='dateRange'
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  selectionMode='range'
                  placeholder={translateWithValues('selectDateRange')}
                  panelClassName='custom-calendar-panel'
                />
              )}
            />

            <Controller
              name='reportFormat'
              control={control}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  options={REPORT_FORMAT_OPTIONS}
                  placeholder={translateWithValues('selectReportFormat')}
                  className='w-full'
                />
              )}
            />

            <Controller
              name='deliveryOptions'
              control={control}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  options={DELIVERY_OPTIONS}
                  placeholder={translateWithValues('selectDeliveryOptions')}
                  className='w-full'
                />
              )}
            />

            <Controller
              name='recipient'
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  type='text'
                  placeholder={translateWithValues('selectRecipient')}
                  className='w-full mt-4 p-0 p-inputtext p-component'
                />
              )}
            />

            <Controller
              name='customerId'
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  type='text'
                  placeholder={translateWithValues('customerId')}
                  className='w-full mt-4 p-0 p-inputtext p-component'
                />
              )}
            />
          </div>
        </div>
      </form>

      <div className='flex justify-center mt-5 gap-2'>
        <Button severity='secondary' type='button'>
          <Translate value='cancel' />
        </Button>
        <Button severity='info' type='submit'>
          <Translate value='generate' />
        </Button>
      </div>
    </>
  );
};

export default GenerateReports;
