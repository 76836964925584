import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { TextInput } from '../../../../components';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { sendToast } from '../../../../store/slices/main/mainSlice';
import {
  DATE_OPTIONS,
  DAYS_OF_WEEK,
  DELIVERY_OPTIONS,
  FREQUENCY_OPTIONS,
  REPORT_FORMAT_OPTIONS,
} from '../../report';

const AddSchedule = ({
  visible,
  setAddScheduleToggle,
  schedule,
  isViewMode = false,
}: {
  visible: boolean;
  setAddScheduleToggle: (visible: boolean) => void;
  schedule?: any;
  isViewMode?: boolean;
}) => {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: schedule || {},
  });
  const formRef = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [frequency, setFrequency] = useState<string | null>(schedule?.frequency || null);

  useEffect(() => {
    if (schedule) {
      const timeParts = schedule.time.split(' ');
      const [hours, minutes] = timeParts[0].split(':');
      const period = timeParts[1];
      const date = new Date();
      date.setHours(period === 'AM' ? parseInt(hours, 10) : parseInt(hours, 10) + 12);
      date.setMinutes(parseInt(minutes, 10));
      reset({ ...schedule, time: date });
    } else {
      reset({});
    }
  }, [visible, schedule, reset]);

  const showToast = (severity: 'success' | 'error', summary: string, detail: string) => {
    dispatch(sendToast({ severity, summary, detail }));
  };

  const onSave = () => {
    // Handle save logic here
    showToast('success', 'Success', 'Schedule created successfully');
    setAddScheduleToggle(false);
    navigate('/reports/schedule');
  };

  const headerElement = () => (
    <div className='flex justify-between items-center w-full'>
      <div className='flex flex-col gap-1'>
        <div className='text-lg font-semibold text-neutral-1'>
          <Translate value={isViewMode ? 'viewScheduleTitle' : 'createScheduleTitle'} />
        </div>
        <div className='text-sm font-regular text-neutral-3'>
          <Translate value='createScheduleDescription' className='!text-sm-regular' />
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className='flex justify-end mt-5 gap-2'>
      <Button severity='secondary' onClick={() => setAddScheduleToggle(false)}>
        <Translate value='cancel' />
      </Button>
      {!isViewMode && (
        <Button
          severity='info'
          onClick={() => {
            if (formRef.current) {
              (formRef.current as HTMLFormElement).dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true }),
              );
            }
          }}
        >
          <Translate value='save' />
        </Button>
      )}
    </div>
  );

  return (
    <Dialog
      draggable={false}
      className='w-[30%] min-w-[] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      header={headerElement}
      visible={visible}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0, minWidth: '400px' }}
      onHide={() => setAddScheduleToggle(false)}
      footer={footerContent}
    >
      {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(onSave)} ref={formRef}>
          <Controller
            name='frequency'
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                options={FREQUENCY_OPTIONS}
                onChange={(e) => {
                  field.onChange(e.value);
                  setFrequency(e.value);
                }}
                placeholder={translateWithValues('selectFrequency')}
                className='w-full mt-4'
                disabled={isViewMode}
              />
            )}
          />

          {frequency === 'weekly' && (
            <Controller
              name='dayOfWeek'
              control={control}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={DAYS_OF_WEEK}
                  placeholder={translateWithValues('selectDay')}
                  className='w-full mt-4'
                  disabled={isViewMode}
                />
              )}
            />
          )}

          {frequency === 'monthly' && (
            <Controller
              name='dateOfMonth'
              control={control}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  options={DATE_OPTIONS}
                  placeholder={translateWithValues('selectDate')}
                  className='w-full mt-4'
                  disabled={isViewMode}
                />
              )}
            />
          )}

          <Controller
            name='time'
            control={control}
            render={({ field }) => (
              <Calendar
                {...field}
                timeOnly
                hourFormat='12'
                placeholder={translateWithValues('selectTime')}
                className='w-full mt-4'
                disabled={isViewMode}
              />
            )}
          />

          <Controller
            name='reportFormat'
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                options={REPORT_FORMAT_OPTIONS}
                placeholder={translateWithValues('selectReportFormat')}
                className='w-full mt-4'
                disabled={isViewMode}
              />
            )}
          />

          <Controller
            name='deliveryOptions'
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                options={DELIVERY_OPTIONS}
                placeholder={translateWithValues('selectDeliveryOptions')}
                className='w-full mt-4'
                disabled={isViewMode}
              />
            )}
          />

          <Controller
            name='recipient'
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                type='text'
                placeholder={translateWithValues('selectRecipient')}
                className='w-full mt-4 p-0 p-inputtext p-component'
                disabled={isViewMode}
              />
            )}
          />

          <Controller
            name='customerId'
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                type='text'
                placeholder={translateWithValues('customerId')}
                className='w-full mt-4 p-0 p-inputtext p-component'
                disabled={isViewMode}
              />
            )}
          />
        </form>
      }
    </Dialog>
  );
};

export default AddSchedule;
