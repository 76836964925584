import Dashboard from './Dashboard';
import GenerateReports from './GenerateReports';
import ScheduleReports from './ScheduleReports';

const reportsRoutes = [
  {
    id: 'reports-dashboard',
    index: true,
    path: 'view',
    handle: {
      breadcrumb: 'view',
    },
    element: <Dashboard />,
  },
  {
    id: 'schedule-reports',
    path: 'schedule',
    index: true,
    handle: {
      breadcrumb: 'Schedule',
    },
    element: <ScheduleReports />,
  },
  {
    id: 'generate-reports',
    path: 'generate',
    index: true,
    handle: {
      breadcrumb: 'Generate',
    },
    element: <GenerateReports />,
  },
];

export default reportsRoutes;
