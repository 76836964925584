import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CurrencySelect, FormatAmount, Status } from '../../../../components';
import QuoteAmountInput from '../../../../components/QuoteAmount';
import useRole from '../../../../hooks/useRoles';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import Switch from '../../../../icons/Switch';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import {
  selectCustomerCurrencies,
  selectSellCurrencies,
} from '../../../../store/slices/refdata/refdataSlice';
import { QuoteStatus, RequestQuickQuoteType } from '../../../../types';
import paymentRole from '../../paymentRole';
import PaymentService from '../../paymentService';
import CountdownTimer from '../CountdownTimer';

interface QuoteAmountProps {
  onSaveAndProceed?: (
    currency: string,
    quoteId: string,
    orderId: number | undefined,
    paymentId: number | undefined,
    buyAmountValue: string,
  ) => void;
  handleStepperValidation?: (formName: RequestQuickQuoteType, isValid: boolean) => void;
}

export const QuoteAmount = ({ onSaveAndProceed, handleStepperValidation }: QuoteAmountProps) => {
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    unregister,
    formState: { errors, isValid },
  } = useForm<any>();
  const dispatch = useAppDispatch();
  const [quoteStatus, setQuoteStatus] = useState(QuoteStatus.AddQuote);
  const [buyCurrency, setBuyCurrency] = useState<CurrencyConfiguration>();
  const [sellCurrency, setSellCurrency] = useState<CurrencyConfiguration>();
  const [quickQuote, setQuickQuote] = useState<QuickQouteProps | null>(null);
  const currencies = useSelector(selectCustomerCurrencies);
  const [currentEditableField, setCurrentEditableField] = useState<
    'margin' | 'rate' | 'inverse' | null
  >(null);
  const [order, setOrder] = useState<Order | null>(null);
  const { quoteId } = useParams();

  const { hasRole } = useRole();
  const buyAmountValue = watch('buyAmount');
  const sellAmountValue = watch('sellAmount');
  const buyCurrencyValue = watch('buyCurrency');
  const sellCurrencyValue = watch('sellCurrency');
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const sellCurrencies = useSelector(selectSellCurrencies);

  useEffect(() => {
    if (selectedCustomer?.currency && sellCurrencies.includes(selectedCustomer?.currency)) {
      setValue('sellCurrency', selectedCustomer?.currency);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    setBuyCurrency(
      currencies.find((val) => {
        return buyCurrencyValue == val.isocode;
      }),
    );
  }, [buyCurrencyValue]);

  useEffect(() => {
    setSellCurrency(
      currencies.find((val) => {
        return sellCurrencyValue == val.isocode;
      }),
    );
  }, [sellCurrencyValue]);

  useEffect(() => {
    if (buyCurrency && buyAmountValue) {
      setValue('buyAmount', parseFloat(buyAmountValue).toFixed(buyCurrency.amountPrecision));
    }
  }, [buyCurrency]);

  useEffect(() => {
    if (sellCurrency && sellAmountValue) {
      setValue('sellAmount', parseFloat(sellAmountValue).toFixed(sellCurrency.amountPrecision));
    }
  }, [sellCurrency]);

  const saveQuickQuote = (data: any) => {
    const newData: any = {
      sellCurrency: data.sellCurrency,
      buyCurrency: data.buyCurrency,
      sellAmount: data.sellAmount ? data.sellAmount : undefined,
      buyAmount: data.buyAmount ? data.buyAmount : undefined,
    };
    if (
      quickQuote &&
      quoteStatus !== QuoteStatus.AddQuote &&
      quoteStatus !== QuoteStatus.QuoteExpired
    ) {
      newData['quoteId'] = quickQuote.quoteId;
      if (currentEditableField === 'rate') {
        newData['rate'] = data.indicativeRate;
      } else if (currentEditableField === 'inverse') {
        newData['inverseRate'] = data.inverse;
      } else {
        newData['margin'] = data.quoteMargin;
      }
    }
    getQuickQuoteMutation.mutate(newData);
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const onSubmit = (data: any) => {
    saveQuickQuote(data);
  };

  const showErrorToast = () => {
    if (handleStepperValidation) {
      handleStepperValidation(RequestQuickQuoteType.QuoteAmounCurrency, isValid);
    }
    if (
      errors['buyAmount']?.type === 'max' ||
      errors['buyAmount']?.type === 'min' ||
      errors['sellAmount']?.type === 'max' ||
      errors['sellAmount']?.type === 'min'
    )
      showToast('error', 'quickQuoteValidationErrorTitle', 'quickQuoteValidationErrorDesc');
    else
      showToast('error', 'client.field.validation.error', 'client.field.validation.error.detail');
  };

  const getQuickQuoteMutation = useMutation({
    mutationFn: async (newData: any) => {
      setQuoteStatus(!newData['quoteId'] ? QuoteStatus.SubmittingQuote : QuoteStatus.RecievingData);
      const response =
        !newData['quoteId'] || quoteStatus === QuoteStatus.QuoteExpired
          ? await PaymentService.getQuickQuote(newData)
          : await PaymentService.recalculateQuote(newData);
      if (response === 'Error') {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'failed',
            detail: !newData['quoteId'] ? 'createQuickQuoteError' : 'recalculateQuoteError',
          }),
        );
        return;
      }
      if (handleStepperValidation) {
        handleStepperValidation(RequestQuickQuoteType.QuoteAmounCurrency, true);
      }

      setQuickQuote(response);
      setValue('quoteMargin', response.appliedMargin);
      setValue('indicativeRate', response.rate);
      setValue('inverse', response.inverseRate);
      setValue('buyAmount', response.buyAmount);
      setValue('sellAmount', response.sellAmount);
      resetEditableState();
      setQuoteStatus(QuoteStatus.DataRecieved);
      return response;
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'failed',
          detail: 'recalculateQuoteError',
        }),
      );
    },
  });
  const quickQuoteByIdQuery = useQuery({
    queryKey: ['quick_quote', quoteId],
    queryFn: async () => {
      if (quoteId) {
        const response = await PaymentService.getQuickQuoteById(quoteId);
        setQuoteStatus(QuoteStatus.DataRecieved);
        setQuickQuote(response?.quote);
        setValue('quoteMargin', response?.quote?.appliedMargin);
        setValue('indicativeRate', response?.quote?.rate);
        setValue('inverse', response?.quote?.inverseRate);
        setValue('buyAmount', response?.quote?.buyAmount);
        setValue('sellAmount', response?.quote?.sellAmount);
        setValue('sellCurrency', response?.quote?.sellCurrency);
        setValue('buyCurrency', response?.quote?.buyCurrency);
        return response;
      }
    },
    refetchOnWindowFocus: false,
  });

  const handleAmountChange = (field: 'buyAmount' | 'sellAmount') => {
    if (field === 'buyAmount' && buyAmountValue) setValue('sellAmount', '');
    else if (field === 'sellAmount' && sellAmountValue) setValue('buyAmount', '');
  };

  const handleFieldEdit = (field: 'margin' | 'rate' | 'inverse') => {
    if (!currentEditableField) {
      setCurrentEditableField(field);
    }
  };

  const handleDecimalInput = (e: React.KeyboardEvent<HTMLInputElement>, precesion?: number) => {
    const input = e.currentTarget.value;
    const key = e.key;
    // Allow control keys like backspace, arrow keys, etc.
    if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key)) {
      return;
    }
    // // Check if the input is a number or a decimal point and validate
    if (!/^[0-9.]$/.test(key)) {
      e.preventDefault();
    }

    // // Prevent more than one decimal point
    if (key === '.' && input.includes('.')) {
      e.preventDefault();
    }
    // Prevent more than two decimal places
    const [, decimals] = input.split('.');
    if (decimals && precesion && decimals.length >= precesion) {
      e.preventDefault();
    }
  };

  const resetEditableState = () => {
    setCurrentEditableField(null);
  };

  const handleCurrencySwitch = () => {
    const { buyAmount, sellAmount } = getValues();
    setValue('buyAmount', sellAmount || '');
    setValue('sellAmount', buyAmount || '');
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('/add')) {
      setQuoteStatus(QuoteStatus.AddQuote);
    } else if (currentUrl.includes('/edit')) {
      if (quoteId) {
        quickQuoteByIdQuery;
      }
    }
  }, []);

  const createOrderMutation = useMutation({
    mutationFn: async () => {
      if (quickQuote?.quoteId) {
        const response = await PaymentService.saveQuote(quickQuote.quoteId);
        const { data } = response;
        if (data.success) {
          showToast('success', 'success', 'quote.order.create.success.message');
          const orderObj: Order = data.data;
          setOrder(orderObj);
          const { payments } = orderObj;
          onSaveAndProceed?.(
            quickQuote?.buyCurrency,
            quickQuote?.quoteId,
            orderObj.id,
            payments[0]?.id ?? 0,
            buyAmountValue,
          );
        } else {
          showToast('error', 'error', 'quote.order.create.error.message');
        }
      }
    },
    onError: () => {
      showToast('error', 'error', 'quote.order.create.error.message');
    },
  });

  return (
    <>
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='quoteAmount' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='quoteAmount.description' />
            </div>
          </div>
          {quoteStatus !== QuoteStatus.AddQuote && (
            <div className='h-fit'>
              <Status status={quoteStatus} />
            </div>
          )}
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit, showErrorToast)}>
          <div
            className={
              (quoteStatus !== QuoteStatus.AddQuote
                ? 'border-b-[1px] border-neutral-border '
                : '') +
              'mt-8 flex flex-col lg:flex-row gap-4 text-neutral-600 text-sm font-medium mb-4 pb-8'
            }
          >
            <CurrencySelect
              label='buy_currency'
              placeholder='currency'
              name='buyCurrency'
              error={errors['buyCurrency']}
              isRequired
              control={control}
              rules={{ required: true }}
              currencySource='buy'
              isEditable={quoteStatus === QuoteStatus.AddQuote}
              className='w-full'
              includeAll={false}
            />

            <QuoteAmountInput
              formRegister={register('buyAmount', {
                required: !sellAmountValue,
                onChange: () => handleAmountChange('buyAmount'),
                min: {
                  value: buyCurrency?.minAmount?.value || 0,
                  message: translateWithValues('sellAmountMin', {
                    minAmount: buyCurrency?.minAmount?.value + ' ' + buyCurrency?.isocode,
                  }),
                },
                ...(buyCurrency?.maxAmount?.value &&
                  buyCurrency?.maxAmount?.value > 0 && {
                    max: {
                      value: buyCurrency.maxAmount.value,
                      message: translateWithValues('sellAmountMax', {
                        maxAmount: buyCurrency?.maxAmount.value + ' ' + buyCurrency?.isocode,
                      }),
                    },
                  }),
                pattern: {
                  value: RegExp(
                    `^\\d+(\\.\\d{${buyCurrency?.amountPrecision && buyCurrency?.amountPrecision > 0 ? 1 : 0},${buyCurrency?.amountPrecision}})?$`,
                  ),
                  message: translateWithValues('amountValidation', {
                    validationValue: buyCurrency?.amountPrecision,
                  }),
                },
              })}
              onKeyDown={(e) => handleDecimalInput(e, buyCurrency?.amountPrecision)}
              error={errors.buyAmount}
              label='buyAmount'
              placeholder='amount'
              className='w-full'
              isRequired={!sellAmountValue}
              isEditMode={quoteStatus == QuoteStatus.AddQuote}
              disabled={Boolean(sellAmountValue)}
              isEditable={quoteStatus !== QuoteStatus.AddQuote}
              value={buyAmountValue}
              currency={buyCurrency?.isocode}
              setQuoteStatus={(val: QuoteStatus) => {
                if (val === QuoteStatus.AddQuote) {
                  setOrder(null);
                  if (onSaveAndProceed) {
                    onSaveAndProceed('', '', undefined, undefined, '');
                  }
                }
                setQuoteStatus(val);
                setQuickQuote(null);
                setValue('sellAmount', '');
                unregister(['quoteMargin', 'indicativeRate', 'inverse']);
              }}
              precision={buyCurrency?.amountPrecision}
            />

            <div className='self-end px-4 md:px-0'>
              <Button
                className='text-transparent transform rotate-90 lg:rotate-0 !py-0 !px-0'
                onClick={(event) => {
                  event.preventDefault();
                  handleCurrencySwitch();
                }}
              >
                <Switch />
              </Button>
            </div>

            <CurrencySelect
              label='sell_currency'
              error={errors['sellCurrency']}
              placeholder='currency'
              name='sellCurrency'
              isRequired
              control={control}
              rules={{ required: true }}
              currencySource='sell'
              isEditable={quoteStatus === QuoteStatus.AddQuote}
              className='w-full'
            />

            <QuoteAmountInput
              formRegister={register('sellAmount', {
                required: !buyAmountValue,
                onChange: () => handleAmountChange('sellAmount'),
                min: {
                  value: sellCurrency?.minAmount?.value || 0,
                  message: translateWithValues('sellAmountMin', {
                    minAmount: sellCurrency?.minAmount?.value + ' ' + sellCurrency?.isocode,
                  }),
                },
                ...(sellCurrency?.maxAmount?.value &&
                  sellCurrency?.maxAmount?.value > 0 && {
                    max: {
                      value: sellCurrency.maxAmount.value,
                      message: translateWithValues('sellAmountMax', {
                        maxAmount: sellCurrency?.maxAmount.value + ' ' + sellCurrency?.isocode,
                      }),
                    },
                  }),
                pattern: {
                  value: RegExp(
                    `^\\d+(\\.\\d{${sellCurrency?.amountPrecision && sellCurrency?.amountPrecision > 0 ? 1 : 0},${sellCurrency?.amountPrecision}})?$`,
                  ),
                  message: translateWithValues('amountValidation', {
                    validationValue: sellCurrency?.amountPrecision,
                  }),
                },
              })}
              onKeyDown={(e) => handleDecimalInput(e, sellCurrency?.amountPrecision)}
              error={errors.sellAmount}
              label='sellAmount'
              placeholder='amount'
              currency={sellCurrency?.isocode}
              className='w-full'
              isEditMode={quoteStatus === QuoteStatus.AddQuote}
              isRequired={!buyAmountValue}
              disabled={Boolean(buyAmountValue)}
              isEditable={quoteStatus !== QuoteStatus.AddQuote}
              value={sellAmountValue}
              setQuoteStatus={(val: QuoteStatus) => {
                if (val === QuoteStatus.AddQuote) {
                  setOrder(null);
                  setOrder(null);
                  if (onSaveAndProceed) {
                    onSaveAndProceed('', '', undefined, undefined, '');
                  }
                }
                setQuoteStatus(val);
                setQuickQuote(null);
                setValue('buyAmount', '');
                unregister(['quoteMargin', 'indicativeRate', 'inverse']);
              }}
              precision={sellCurrency?.amountPrecision}
            />
            {quickQuote &&
              quickQuote?.profit != undefined &&
              quoteStatus === QuoteStatus.DataRecieved && (
                <div
                  className={
                    (quickQuote.profit >= 0
                      ? 'border-success-content-default text-success-content-default '
                      : 'border-error-content-default text-error-content-default ') +
                    'rounded-[10px] border-[1px] text-lg-semibold flex items-center px-[20px] py-2 text-nowrap h-fit self-center'
                  }
                >
                  <span>
                    {quickQuote.profit >= 0
                      ? translateWithValues('profit')
                      : translateWithValues('loss')}{' '}
                    <FormatAmount
                      number={parseFloat(String(quickQuote?.profit) || '0')}
                      maximumFractionDigits={
                        currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                          ?.amountPrecision
                      }
                    />
                    {' ' + quickQuote.sellCurrency}
                  </span>
                </div>
              )}
          </div>
          {![QuoteStatus.AddQuote, QuoteStatus.SubmittingQuote].includes(quoteStatus) && (
            <div className='flex flex-col lg:flex-row mt-8 gap-4 p-8 border-[1px] border-neutral-surface-dark rounded-[8px]'>
              {hasRole(paymentRole.editPayments) && (
                <div className='flex-1'>
                  <QuoteAmountInput
                    formRegister={register('quoteMargin', {
                      required: quoteStatus !== QuoteStatus.AddQuote,
                      onChange: () => {
                        handleFieldEdit('margin');
                      },
                      min: {
                        value: quickQuote?.threshold?.marginLow || 0,
                        message: translateWithValues('percentageMin', {
                          value: quickQuote?.threshold?.marginLow,
                        }),
                      },
                      max: {
                        value: quickQuote?.threshold?.marginHigh || 0,
                        message: translateWithValues('percentageMax', {
                          value: quickQuote?.threshold?.marginHigh,
                        }),
                      },
                    })}
                    error={errors.quoteMargin}
                    label='quoteMargin'
                    placeholder='amount'
                    className='w-full mb-2'
                    disabled={currentEditableField !== null && currentEditableField !== 'margin'}
                    isEditMode={quoteStatus === QuoteStatus.DataRecieved}
                  />
                  <QuoteAmountInput
                    formRegister={register('indicativeRate', {
                      required: quoteStatus !== QuoteStatus.AddQuote,
                      onChange: () => {
                        handleFieldEdit('rate');
                      },
                      min: {
                        value: quickQuote?.threshold?.rateLow || 0,
                        message: translateWithValues('percentageMin', {
                          value: quickQuote?.threshold?.rateLow,
                        }),
                      },
                      max: {
                        value: quickQuote?.threshold?.rateHigh || 0,
                        message: translateWithValues('percentageMax', {
                          value: quickQuote?.threshold?.rateHigh,
                        }),
                      },
                    })}
                    error={errors.indicativeRate}
                    label='indicativeRate'
                    placeholder='amount'
                    className='w-full mb-2'
                    disabled={currentEditableField !== null && currentEditableField !== 'rate'}
                    isEditMode={quoteStatus === QuoteStatus.DataRecieved}
                  />
                  <QuoteAmountInput
                    formRegister={register('inverse', {
                      required: quoteStatus !== QuoteStatus.AddQuote,
                      onChange: () => {
                        handleFieldEdit('inverse');
                      },
                      min: {
                        value: quickQuote?.threshold?.inverseRateLow || 0,
                        message: translateWithValues('percentageMin', {
                          value: quickQuote?.threshold?.inverseRateLow,
                        }),
                      },
                      max: {
                        value: quickQuote?.threshold?.inverseRateHigh || 0,
                        message: translateWithValues('percentageMax', {
                          value: quickQuote?.threshold?.inverseRateHigh,
                        }),
                      },
                    })}
                    error={errors.inverse}
                    label='inverse'
                    placeholder='amount'
                    className='w-full mb-2'
                    isEditMode={quoteStatus === QuoteStatus.DataRecieved}
                    disabled={currentEditableField !== null && currentEditableField !== 'inverse'}
                  />
                </div>
              )}
              <div className='flex flex-1 flex-col md:flex-row gap-4 items-center bg-primary-surface-light justify-between rounded-[8px] px-4 py-[20px] min-h-[210px]'>
                {quoteStatus === QuoteStatus.RecievingData ||
                quoteStatus === QuoteStatus.SubmittingQuote ? (
                  <Translate value='calculatingRate' className='text-primary text-xs-bold' />
                ) : (
                  <>
                    <div className='grid'>
                      <Translate value='quote_received' className='text-primary-dark-content' />
                      <span className='text-primary-blue-surface-dark text-2xl-bold'>
                        <FormatAmount
                          number={parseFloat(quickQuote?.sellAmount || '0')}
                          maximumFractionDigits={sellCurrency?.amountPrecision}
                        />
                        <span>{' ' + quickQuote?.sellCurrency}</span>
                      </span>
                      <div className='mt-4 bg-primary-blue text-primary-dark-content text-sm-medium w-fit p-2 rounded-lg'>
                        At 1 {quickQuote?.sellCurrency + ' = '}
                        <FormatAmount
                          number={parseFloat(
                            (quickQuote?.direction == 'IN'
                              ? quickQuote.inverseRate
                              : quickQuote?.rate) ?? '0',
                          )}
                          maximumFractionDigits={sellCurrency?.ratePrecision}
                        />
                        {' ' + quickQuote?.buyCurrency}
                      </div>
                    </div>
                    {quickQuote?.createdTime && (
                      <CountdownTimer
                        createdTime={quickQuote?.createdTime}
                        onTimeOut={() => {
                          setQuoteStatus(QuoteStatus.QuoteExpired);
                          dispatch(
                            sendToast({
                              severity: 'error',
                              summary: 'alert',
                              detail: 'quoteExpired',
                            }),
                          );
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          <div className='flex items-center justify-center mt-10 gap-5'>
            <Button
              loading={getQuickQuoteMutation.isPending}
              disabled={getQuickQuoteMutation.isPending}
              type='submit'
              severity={
                !quickQuote?.quoteId || quoteStatus === QuoteStatus.AddQuote ? 'info' : 'contrast'
              }
              className='w-48 inline-flex items-center justify-center'
            >
              <Translate
                value={
                  !quickQuote?.quoteId || quoteStatus === QuoteStatus.AddQuote
                    ? 'getQuickQuote'
                    : 'recalculate'
                }
              />
            </Button>

            {order == null && !getQuickQuoteMutation.isPending && quickQuote?.quoteId && (
              <Button
                disabled={!isValid || createOrderMutation.isPending}
                loading={createOrderMutation.isPending}
                type='submit'
                severity='info'
                className='ml-1'
                onClick={(event: any) => {
                  event.preventDefault();
                  createOrderMutation.mutate();
                }}
              >
                <Translate value={'quote.save'} />
              </Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
