import CreateBeneficiary from './Create';
import ManageBeneficiaries from './Manage';

const beneficiaryRoutes = [
  {
    id: 'manage_beneficiaries',
    path: 'manage',
    handle: {
      breadcrumb: 'Manage Beneficiaries',
    },
    element: <ManageBeneficiaries />,
  },
  {
    id: 'create_beneficiary',
    path: 'create',
    handle: {
      breadcrumb: 'Add New Beneficiary',
    },
    element: <CreateBeneficiary />,
  },
];

export default beneficiaryRoutes;
