import { Status } from '../../types';

const BENEFICIARY_STATUS_OPTIONS = [
  { label: 'Active', value: Status.ACTIVE },
  { label: 'Inactive', value: Status.INACTIVE },
  { label: 'Pending approval', value: Status.PENDING_APPROVAL },
  { label: 'Saved as draft', value: 'SAVED_AS_DRAFT' },
  { label: 'Rejected due to corrections', value: 'REJECTED_DUE_TO_CORRECTIONS' },
] as const;

export { BENEFICIARY_STATUS_OPTIONS };
