import React, { useContext, useEffect, useState } from 'react';
import { CustomStepper } from '../../../../components';
import { PageHeadContext } from '../../../../providers/PageHead';
import { AddOrEditOrView, RequestQuickQuoteType } from '../../../../types';
import { BeneficiaryDetails } from '../BeneficiaryDetails';
import { CustomerDetails } from '../CustomerDetails';
import { QuoteAmount } from '../QuoteAmount';

interface Step {
  id: string;
  content: React.ReactNode;
}

const RequestQuickQuote = ({ mode }: { mode: AddOrEditOrView }) => {
  const [activeStep, setActiveStep] = useState<string>(RequestQuickQuoteType.QuoteAmounCurrency);
  const [visited, setVisited] = useState<Set<string>>(new Set());
  const [visitPermitted, setVisitPermitted] = useState<Set<string>>(new Set());
  const [validationOnStepper, setValidationOnStepper] = useState<Set<string>>(new Set());
  const [quoteId, setQuoteId] = useState<string>('');
  const [buyCurrency, setBuyCurrency] = useState<string>();
  const [buyAmount, setBuyAmount] = useState<number>();
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
  const [orderId, setOrderId] = useState<number | undefined>();
  const [paymentId, setPaymentId] = useState<number | undefined>();
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  useEffect(() => {
    setVisitPermitted(new Set([RequestQuickQuoteType.QuoteAmounCurrency]));
    setPageHeadData({
      title: 'requestQuickQuote',
      description: 'requestQuickQuoteDescription',
    });
  }, [setPageHeadData]);

  const handleStepperValidation = (formName: RequestQuickQuoteType, isValid: boolean) => {
    setValidationOnStepper((prev: Set<string>) => {
      const newValidations = new Set(prev);
      if (!isValid) {
        newValidations.add(formName);
      } else {
        newValidations.delete(formName);
      }
      return newValidations;
    });
  };

  const steps: Step[] = [
    {
      id: RequestQuickQuoteType.QuoteAmounCurrency,
      content: (
        <QuoteAmount
          onSaveAndProceed={(
            currency: string,
            id: string,
            orderId: number | undefined,
            paymentId: number | undefined,
            buyAmountValue: string,
          ) => {
            setQuoteId(id || '');
            setOrderId(orderId);
            setPaymentId(paymentId);
            if (id) {
              setBuyCurrency(currency);
              setBuyAmount(Number(buyAmountValue));
              setVisitPermitted(
                new Set([...visitPermitted, RequestQuickQuoteType.QuoteAmounCurrency]),
              );
              setVisited(new Set([...visited, RequestQuickQuoteType.QuoteAmounCurrency]));
              if (
                mode === AddOrEditOrView.Add &&
                !visited.has(RequestQuickQuoteType.QuoteAmounCurrency)
              ) {
                setActiveStep(RequestQuickQuoteType.QuoteAmounCurrency);
              } else {
                setActiveStep('');
              }
            }
          }}
          handleStepperValidation={handleStepperValidation}
        />
      ),
    },
    {
      id: RequestQuickQuoteType.CustomerDetails,
      content: (
        <CustomerDetails
          quoteId={quoteId}
          orderId={orderId}
          paymentId={paymentId}
          onSaveAndProceed={(client: CustomerDetails) => {
            setCustomerDetails(client);
            setVisitPermitted(new Set([...visitPermitted, RequestQuickQuoteType.CustomerDetails]));
            setVisited(new Set([...visited, RequestQuickQuoteType.CustomerDetails]));
            if (
              mode === AddOrEditOrView.Add &&
              !visited.has(RequestQuickQuoteType.CustomerDetails)
            ) {
              setActiveStep(RequestQuickQuoteType.CustomerDetails);
            } else {
              setActiveStep('');
            }
          }}
        />
      ),
    },
    {
      id: RequestQuickQuoteType.BeneficiaryDetails,
      content: (
        <BeneficiaryDetails
          orderId={orderId!}
          disabled={!quoteId || !buyCurrency || !customerDetails}
          quoteId={quoteId}
          paymentId={paymentId!}
          buyCurrency={buyCurrency!}
          buyAmount={buyAmount!}
        />
      ),
    },
  ];

  return (
    <>
      <div className='pt-10 mx-auto rounded-md'>
        <CustomStepper
          steps={steps}
          activeStep={activeStep}
          visited={visited}
          validations={validationOnStepper}
        />
      </div>
    </>
  );
};

export default RequestQuickQuote;
