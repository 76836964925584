export const FREQUENCY_OPTIONS = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];

export const DAYS_OF_WEEK = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' },
];

export const DATE_OPTIONS = Array.from({ length: 28 }, (_, i) => ({
  label: `${i + 1}`,
  value: i + 1,
}));

export const REPORT_FORMAT_OPTIONS = [
  { label: 'PDF', value: 'pdf' },
  { label: 'Excel', value: 'excel' },
  { label: 'CSV', value: 'csv' },
];

export const DELIVERY_OPTIONS = [
  { label: 'Email', value: 'email' },
  { label: 'FTP', value: 'ftp' },
  { label: 'SFTP', value: 'sftp' },
];
